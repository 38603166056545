/* You can add global styles to this file, and also import other style files */

/* NGPRIME Styles to import in component CSS -----------------> */
/* @import "./PRIMENG_Styles/primengStyles.css"; */
/* NGPRIME Styles to import in component CSS ----------------<- */

/* #region [@import - Fonts] */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Clicker+Script&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap'); */
/* #endregion */

@import "animate.css/animate.css";
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

/* FONTS */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Arvo:ital,wght@0,400;0,700;1,400;1,700&display=swap');
  @import url('https://fonts.googleapis.com/css2?family=Rouge+Script&display=swap'); */

/* Stop Print */
@media print {
  html,
  body {
    /* display: none; */
  }
}

.unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer; /* Optional: Set the cursor to default */
}

/* #region [Color Variables] */
:root {
  /* #region [OLD] */
  --visible-01: #8bc34a;
  --hidden-01: #536dfe;

  /* #region [New Logo Colors] */

  /* Orange */
  --C-1: #e97d22;
  --C-1-RGB: 233, 125, 34;

  /* Blue */
  --C-2: #2481bc;
  --C-2-RGB: 36, 129, 188;

  /* Pink */
  --C-3: #ee1e72;
  --C-3-RGB: 238, 30, 114;

  /* Teal */
  --C-4: #3fb9ae;
  --C-4-RGB: 63, 185, 74;

  /* #endregion */

  /* #region [EWM - Colors] */
  /* Approve */
  --M-color-EWM-1: #22c55e;
  --M-color-EWM-1-RGB: 34, 197, 94;
  --E-color-EWM-1: #ff0800;
  --E-color-EWM-1-RGB: 255, 8, 0;
  --W-color-EWM-1: #f59e0b;
  --W-color-EWM-1-RGB: 245, 158, 11;
  --color-EWM-s: #b6b6b6;
  --color-EWM-s-RGB: 245, 158, 11;
  /* #endregion */

  /* #region [PAR - Colors] */
  /* Approve */
  --approve-color-PAR-1: #22c55e;
  --approve-color-PAR-1-RGB: 34, 197, 94;
  --reject-color-PAR-1: #ff0800;
  --reject-color-PAR-1-RGB: 255, 8, 0;
  --pending-color-PAR-1: #f59e0b;
  --pending-color-PAR-1-RGB: 245, 158, 11;
  --pending-color-PAR-s: #b6b6b6;
  --pending-color-PAR-s-RGB: 245, 158, 11;
  /* #endregion */

  /* #region [VARF - Colors] */
  --view-color-1: #2258c5;
  --view-color-1-RGB: 34, 88, 197;
  --approve-color-1: #22c55e;
  --approve-color-1-RGB: 34, 197, 94;
  --reject-color-1: #ff0800;
  --reject-color-1-RGB: 255, 8, 0;
  --forward-color-1: #f59e0b;
  --forward-color-1-RGB: 245, 158, 11;
  /* #endregion */

  /* #region [Chart Color - 4x] */
  --CC-4x-01: #007af5;
  --CC-4x-01-RGB: 0, 122, 245;

  --CC-4x-02: #ff674c;
  --CC-4x-02-RGB: 255, 103, 76;

  --CC-4x-03: #fad600;
  --CC-4x-03-RGB: 250, 214, 0;

  --CC-4x-04: #29d2ff;
  --CC-4x-04-RGB: 41, 210, 255;

  /* #endregion */

  --text-color-L0: #c0c0c0;
  --text-color-L1: #36454f;

  --text-color-D0: #1a1a1a;
  --text-color-D1: #36454f;

  --white: #fff;

  /* Light Theme Color */

  --bg-color-W: #ffffff;
  --bg-color-0: #fafafc;
  --bg-color-1: #e8eaef;
  --bg-color-2: #cacbcd;
  --bg-color-3: #fafafa;
  --bg-color-4: #fffafa;
  --bg-color-5: #fdeeff;

  --primary-color: #786fb9;
  --primary-color-RGB: 120, 111, 185;

  --secondary-color: #4f6272;
  --secondary-color-RGB: 79, 98, 114;

  --secondary-color-1: #8739f9;
  --secondary-color-1-RGB: 135, 57, 249;

  --secondary-color-2: #d8e2dc;
  --secondary-color-2-RGB: 216, 226, 220;

  --secondary-color-3: #f59e0b;
  --secondary-color-3-RGB: 245, 159, 10;

  --secondary-colo-5: #979797;
  --secondary-colo-5-RGB: 150, 150, 150;

  /* Button Colors */
  --button-color-1: #786fb9;
  --button-color-1-RGB: 119, 110, 185;

  /* Shadow */
  --Shadow-color-P: #786fb9;
  --Shadow-color-P-RGB: 120, 111, 185;
  --Shadow-color-P-1: rgba(var(--Shadow-color-P-RGB), 0.2);
  --Shadow-P-1: 0 0 0 0.2rem var(--Shadow-color-P-1);

  /* Gradient Color */
  /* #region [Gradient-1] */
  --gradient-1-C1: #86abef;
  --gradient-1-C1-RGB: 255, 94, 77;
  --gradient-1-C2: #0974f1;
  --gradient-1-C2-RGB: 255, 9, 0;

  --linear-gradient-1: linear-gradient(
    90deg,
    var(--gradient-1-C1) 0%,
    var(--gradient-1-C2) 100%
  );
  /* #endregion */

  /* #region [Gradient-2] */
  --gradient-2-C1: #2380bf;
  --gradient-2-C1-RGB: 255, 94, 77;
  --gradient-2-C2: #2480c0;
  --gradient-2-C2-RGB: 255, 9, 0;

  --linear-gradient-2: linear-gradient(
    90deg,
    var(--gradient-2-C1) 0%,
    var(--gradient-2-C2) 100%
  );
  /* #endregion */

  /* #region [Gradient-3] */
  --gradient-3-C1: #3f6eb6;
  --gradient-3-C1-RGB: 62, 110, 182;
  --gradient-3-C2: #254ba0;
  --gradient-3-C2-RGB: 38, 77, 161;

  --linear-gradient-3: linear-gradient(
    90deg,
    var(--gradient-3-C1) 0%,
    var(--gradient-3-C2) 100%
  );
  --linear-gradient-3v: linear-gradient(
    180deg,
    var(--gradient-3-C1) 0%,
    var(--gradient-3-C2) 100%
  );
  /* #endregion */

  /* #region [Gradient-4] */
  --gradient-4-C1: #786fb9;
  --gradient-4-C1-RGB: 62, 110, 182;
  --gradient-4-C2: #695faf;
  --gradient-4-C2-RGB: 38, 77, 161;

  --linear-gradient-4: linear-gradient(
    90deg,
    var(--gradient-4-C1) 0%,
    var(--gradient-4-C2) 100%
  );
  --linear-gradient-4v: linear-gradient(
    180deg,
    var(--gradient-4-C1) 0%,
    var(--gradient-4-C2) 100%
  );
  /* #endregion */

  /* Error Color */
  --error-color-1: #ff0800;

  /* Red Color */
  --red-color-1: #ff0800;
  --red-color-1-RGB: 255, 9, 0;

  /* Blue Color */
  --blue-color-1: #7733ff;
  --blue-color-1-RGB: 119, 51, 255;

  --blue-color-2: #89cff0;
  --blue-color-2-RGB: 137, 207, 240;

  --blue-color-3: #0070bb;
  --blue-color-3-RGB: 0, 112, 187;

  /* Green Color */
  --green-color-1: #7bbd00;
  --green-color-1-RGB: 123, 189, 0;

  /* SideBar Buttons */
  --sidebar-button-1: #786fb9;
  --sidebar-button-1-RGB: 119, 110, 185;

  --body-color: #f6f5fb;
  --bar-color: #fff;

  --border-color-1: #c9c5cb;
  --border-color-1f: #a09da1;

  --text-color-1: #64728c;
  --text-color-2: #858585;
  --text-color-3: #7a859c;
  --text-color-4: #929baa;
  --text-color-5: #919191;
  --text-color-white: #fff;
  --text-color-Green: #70ac00;
  --text-color-Green-l: #70ac0080;

  --placeholder-color-1: #c9c9c9;

  --button-light: #f8f9fa;

  --shadow-color-1: #c9c5cb;

  --light-liner-0: #f1f2f3;
  --light-liner-1: #ebebeb;
  --light-liner-2: #d7d9d7;
  --light-liner-3: #a0adc5;

  --focus-color-1: #ffcbdd;

  --warning-light-1: #ff00001c;

  /* ====== Transition ====== */
  --tran-03: all 0.2s ease;
  --tran-03: all 0.3s ease;
  --tran-04: all 0.3s ease;
  --tran-05: all 0.3s ease;

  /* ===== COLORS ===== */
  --green-light-01: #cee26b;
  --gray-color-01: #898888;

  /* ===== PRIMENG - COLORS ===== */

  /* Table Header th Color */
  --TBL-row-th-01: #786fb9;

  /* Table row highlight color */
  --TBL-row-H-01: #fdeeff;

  /* Table Check-Box color */
  --TBL-CB-01: #b33bf6;
  /* #endregion */

  /* ************************************************************************************************ */
  /* ************************************************************************************************ */
  /* ************************************************************************************************ */

  /* #region [NEW] */

  /* #region [PRIME NG (--PrNG-)] */

  --PrNG-Primary-color: #786fb9;
  --PrNG-Primary-color-RGB: 120, 111, 185;

  --PrNG-color-green-1: #22c55e;
  --PrNG-color-gray-lg-1: #8e8e8e;
  --PrNG-color-gray-lg-2: #adadad;
  --PrNG-color-gray-lg-3: #e9e9e9;
  --PrNG-color-white-1: #fff;

  /* #region [Background Colors (--PrNG-BG > -LT | -MD | -C > -color > -P | -{n})] */
  --PrNG-BG-LT-color-P: rgba(var(--PrNG-Primary-color-RGB), 0.2);
  /* #endregion */

  /* #region [text-colors (--PrNG-text-color-)] */
  --PrNG-text-color-P: #786fb9;
  --PrNG-text-color-C: #8e8e8e;
  --PrNG-text-color-1: #64728c;
  --PrNG-text-color-2: #858585;
  --PrNG-text-color-3: #7a859c;
  --PrNG-text-color-4: #929baa;
  --PrNG-text-color-5: #919191;
  --PrNG-text-color-error: #ff0800;
  /* #endregion */

  /* #region [border-colors (--PrNG-border-color-)] */
  --PrNG-border-color-P: #786fb9;
  --PrNG-border-color-C: #8e8e8e;
  --PrNG-border-color-1: #64728c;
  --PrNG-border-color-2: #858585;
  --PrNG-border-color-3: #7a859c;
  --PrNG-border-color-4: #929baa;
  --PrNG-border-color-5: #919191;
  --PrNG-border-color-error: #ff0800;
  /* #endregion */

  --m-inputText-color-1: #4e4e4e;

  /* #region [Color - BG, Color (--PrNG-BTN-{BG,Color,Border...}-color-)] */
  --PrNG-BTN-Color-color-P: #786fb9;
  --PrNG-BTN-Color-color-P-RGB: 120, 111, 185;
  --PrNG-BTN-BG-color-P: rgba(var(--PrNG-BTN-Color-color-P-RGB), 0.6);
  /* #endregion */

  --PrNG-inputRow-border-color-P: #786fb9;
  --PrNG-inputRow-border-color-P-RGB: 120, 111, 185;
  --PrNG-inputRow-BG-color-P-1: rgba(
    var(--PrNG-inputRow-border-color-P-RGB),
    0.1
  );
  --PrNG-inputRow-Shadow-color-P-1: rgba(
    var(--PrNG-inputRow-border-color-P-RGB),
    0.2
  );
  --PrNG-inputRow-BoxShadow-P-1: 0 0 0 0.2rem
    var(--PrNG-inputRow-Shadow-color-P-1);

  --PrNG-border-color-P: #786fb9;
  --PrNG-border-color-P-RGB: 120, 111, 185;
  --PrNG-BG-color-P-1: rgba(var(--PrNG-border-color-P-RGB), 0.1);
  --PrNG-Shadow-color-P-1: rgba(var(--PrNG-border-color-P-RGB), 0.2);
  --PrNG-BoxShadow-P-1: 0 0 0 0.2rem var(--PrNG-Shadow-color-P-1);

  --PrNG-color-1: #64728c;
  --PrNG-border-color-1-RGB: 100, 114, 140;
  --PrNG-BG-color-1-1: rgba(var(--PrNG-border-color-1-RGB), 0.1);
  --PrNG-Shadow-color-1-1: rgba(var(--PrNG-border-color-1-RGB), 0.2);
  --PrNG-BoxShadow-1-1: 0 0 0 0.2rem var(--PrNG-Shadow-color-1-1);

  /* #region [Color - Border, Shadow {D, Error, Valid} (--PrNG-border-color-)] */
  --PrNG-border-color-D: #d1d5db;
  /* --PrNG-border-color-D-RGB: 209, 213, 219;
            --PrNG-Shadow-color-D-1: rgba(var(--PrNG-border-color-D-RGB), 0.2);
            --PrNG-BoxShadow-inputFields-D-1: 0 0 0 0.2rem var(--PrNG-Shadow-color-D-1); */

  --PrNG-border-color-D: #d1d5db;
  --PrNG-border-color-D-RGB: 209, 213, 219;
  --PrNG-Shadow-color-D-1: rgba(var(--PrNG-border-color-D-RGB), 0.2);
  --PrNG-BoxShadow-inputFields-D-1: 0 0 0 0.2rem var(--PrNG-Shadow-color-D-1);

  --PrNG-border-color-P: #786fb9;
  --PrNG-border-color-P-RGB: 120, 111, 185;
  --PrNG-Shadow-color-P-1: rgba(var(--PrNG-border-color-P-RGB), 0.2);
  --PrNG-BoxShadow-inputFields-P-1: 0 0 0 0.2rem var(--PrNG-Shadow-color-P-1);

  /* Error */
  --PrNG-border-color-Error: #ff0800;
  --PrNG-border-color-Error-RGB: 255, 8, 0;
  --PrNG-Shadow-color-Error-1: rgba(var(--PrNG-border-color-Error-RGB), 0.2);
  --PrNG-BoxShadow-inputFields-Error-1: 0 0 0 0.2rem
    var(--PrNG-Shadow-color-Error-1);

  /* Filled & Valid */
  --PrNG-border-color-VnF: #88c516;
  --PrNG-border-color-VnF-RGB: 136, 197, 22;
  --PrNG-Shadow-color-VnF-1: rgba(var(--PrNG-border-color-VnF-RGB), 0.2);
  --PrNG-BoxShadow-inputFields-VnF-1: 0 0 0 0.2rem
    var(--PrNG-Shadow-color-VnF-1);
  /* #endregion */

  /* #region [Color - Border, Shadow {D, Error, Valid} (--InpF-border-color-)] */
  --InpF-border-color-D: #d1d5db;
  /* --InpF-border-color-D-RGB: 209, 213, 219;
            --InpF-Shadow-color-D-1: rgba(var(--InpF-border-color-D-RGB), 0.2);
            --InpF-BoxShadow-inputFields-D-1: 0 0 0 0.2rem var(--InpF-Shadow-color-D-1); */

  --InpF-border-color-P: #786fb9;
  --InpF-border-color-P-RGB: 120, 111, 185;
  --InpF-Shadow-color-P-1: rgba(var(--InpF-border-color-P-RGB), 0.2);
  --InpF-BoxShadow-inputFields-P-1: 0 0 0 0.2rem var(--InpF-Shadow-color-P-1);

  /* Error */
  --InpF-border-color-Error: #ff0800;
  --InpF-border-color-Error-RGB: 255, 8, 0;
  --InpF-Shadow-color-Error-1: rgba(var(--InpF-border-color-Error-RGB), 0.2);
  --InpF-BoxShadow-inputFields-Error-1: 0 0 0 0.2rem
    var(--InpF-Shadow-color-Error-1);

  /* Filled & Valid */
  --InpF-border-color-VnF: #88c516;
  --InpF-border-color-VnF-RGB: 136, 197, 22;
  --InpF-Shadow-color-VnF-1: rgba(var(--InpF-border-color-VnF-RGB), 0.2);
  --InpF-BoxShadow-inputFields-VnF-1: 0 0 0 0.2rem
    var(--InpF-Shadow-color-VnF-1);
  /* #endregion */

  /* #region [Color - Confirm Dialog Colors - Border, Background, Text - Normal, Hover, Focus, Disabled] */

  /* ICON */
  --m-CD-icon-color-BG-1: #ff0800;
  --m-CD-icon-color-BG-1-RGB: 255, 8, 0;
  --m-CD-icon-color-1: #ffebeb;
  --m-CD-icon-color-BG-Light-1: rgba(var(--m-CD-icon-color-BG-1-RGB), 0.1);

  /* Cancel */
  --m-cancel-color-BG-1: #929baa;
  --m-cancel-color-BG-1-RGB: 146, 155, 170;
  --m-cancel-color-BG-Light-1: rgba(var(--m-cancel-color-BG-1-RGB), 0.1);

  /* #endregion */

  /* #region [Color - Button Colors - Border, Background, Text - Normal, Hover, Focus, Disabled] */

  /* Delete */
  --m-delete-color-BG-1: #ff0800;
  --m-delete-color-BG-1-RGB: 255, 8, 0;
  --m-delete-color-BG-Light-1: rgba(var(--m-delete-color-BG-1-RGB), 0.1);

  /* Cancel */
  --m-cancel-color-BG-1: #929baa;
  --m-cancel-color-BG-1-RGB: 146, 155, 170;
  --m-cancel-color-BG-Light-1: rgba(var(--m-cancel-color-BG-1-RGB), 0.1);

  /* #endregion */

  /* #region [PrNG - DropDowns] */

  /* #region [PrNG - DropDown - PrNG_DD_v0] */
  --PrNG_DD_v0-color-P: #786fb9;
  --PrNG_DD_v0-color-P-RGB: 120, 111, 185;

  --PrNG_DD_v0-S_Option-BG-1: rgba(var(--PrNG_DD_v0-color-P-RGB), 0.2);
  --PrNG_DD_v0-S_Option-C-1: var(--PrNG_DD_v0-color-P);

  --PrNG_DD_v0-border-H-1: #786fb9;
  --PrNG_DD_v0-border-H-1-RGB: 120, 111, 185;
  --PrNG_DD_v0-text-S-1: #64728c;

  --PrNG_DD_v0-Shadow-color-P-1: rgba(var(--PrNG_DD_v0-color-P-RGB), 0.2);
  --PrNG_DD_v0-BoxShadow-P-1: 0 0 0 0.2rem var(--PrNG_DD_v0-Shadow-color-P-1);

  /* #endregion */

  /* #region [PrNG - DropDown - PrNG_DD_v1] */
  --PrNG_DD_v1-color-P: #786fb9;
  --PrNG_DD_v1-color-P-RGB: 120, 111, 185;

  --PrNG_DD_v1-S_Option-BG-1: rgba(var(--PrNG_DD_v1-color-P-RGB), 0.2);
  --PrNG_DD_v1-S_Option-C-1: var(--PrNG_DD_v1-color-P);

  --PrNG_DD_v1-border-H-1: #786fb9;
  --PrNG_DD_v1-border-H-1-RGB: 120, 111, 185;
  --PrNG_DD_v1-text-S-1: #64728c;

  --PrNG_DD_v1-Shadow-color-P-1: rgba(var(--PrNG_DD_v1-color-P-RGB), 0.2);
  --PrNG_DD_v1-BoxShadow-P-1: 0 0 0 0.2rem var(--PrNG_DD_v1-Shadow-color-P-1);

  /* #endregion */

  /* #endregion */

  /* Table Header th Color */
  --TBL-row-th-01: #786fb9;
  /* Table row highlight color */
  --TBL-row-H-01: #fdeeff;
  /* Table Check-Box color */
  --TBL-CB-01: #b33bf6;

  /* #region [PrNG - Table] */

  /* Table - Background */
  --PrNG-TBL-BG-color-1: #e8eaef;

  /* Table > Body - Background */
  --PrNG-TBL-body-BG-color-1: #fff;

  /* Table - Text */
  --PrNG-TBL-text-color-P: #786fb9;
  --PrNG-TBL-row-th-text-color-1: #fff;
  --PrNG-TBL-row-tr-text-color-1: #64728c;

  --PrNG-TBL-row-th-BG-color-1: #786fb9;
  --PrNG-TBL-row-th-BG-H-color-01: #fdeeff;
  --PrNG-TBL-CB-color-01: #b33bf6;

  /* #endregion */

  /* #region [PrNG - Dialog] */
  --PrNG-DLG-BG-color-1: #000000;

  /* Dialog Icons Color */
  --PrNG-DLG-ICO-color-P: #786fb9;
  --PrNG-DLG-ICO-color-P-RGB: 120, 111, 185;
  --PrNG-DLG-ICO-color-R: #ff0800;
  --PrNG-DLG-ICO-color-R-RGB: 255, 8, 0;

  /* #endregion */

  /* #endregion */

  /* #region [Colors - Error] */
  --m-error-color-1: #ff0800;
  --m-error-color-RGB: 255, 8, 0;

  /* #endregion */

  /* #endregion */

  /* #region [Toggler Color Scheme] */
  --TG-BG-color-01: #ff0800;
  --TG-BG-color-01-RGB: 255, 8, 0;

  --TG-BG-color-02: #786fb9;
  --TG-BG-color-02-RGB: 120, 111, 185;

  /* #endregion */
}

/* #endregion */

/* #region [CSS Classes - Poppins] */

/* Poppins Thin */
@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Poppins Thin */
@font-face {
  font-family: "Poppins Thin";
  src: url("./assets/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Poppins Thin Italic */
@font-face {
  font-family: "Poppins Thin Italic";
  src: url("./assets/fonts/Poppins/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* Poppins ExtraLight */
@font-face {
  font-family: "Poppins ExtraLight";
  src: url("./assets/fonts/Poppins/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* Poppins ExtraLight Italic */
@font-face {
  font-family: "Poppins ExtraLight Italic";
  src: url("./assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf")
    format("truetype");
  font-weight: 200;
  font-style: italic;
}

.poppins {
  font-family: "Poppins", sans-serif;
}

.poppins-thin {
  font-family: "Poppins Thin", sans-serif;
  font-weight: 100;
}

.poppins-thin-italic {
  font-family: "Poppins Thin Italic", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight {
  font-family: "Poppins ExtraLight", sans-serif;
  font-weight: 200;
}

.poppins-extralight-italic {
  font-family: "Poppins ExtraLight Italic", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light {
  font-family: "Poppins Light", sans-serif;
  font-weight: 300;
}

.poppins-light-italic {
  font-family: "Poppins Light Italic", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular {
  font-family: "Poppins Regular", sans-serif;
  font-weight: 400;
}

.poppins-regular-italic {
  font-family: "Poppins Regular Italic", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium {
  font-family: "Poppins Medium", sans-serif;
  font-weight: 500;
}

.poppins-medium-italic {
  font-family: "Poppins Medium Italic", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold {
  font-family: "Poppins SemiBold", sans-serif;
  font-weight: 600;
}

.poppins-semibold-italic {
  font-family: "Poppins SemiBold Italic", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold {
  font-family: "Poppins Bold", sans-serif;
  font-weight: 700;
}

.poppins-bold-italic {
  font-family: "Poppins Bold Italic", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold {
  font-family: "Poppins ExtraBold", sans-serif;
  font-weight: 800;
}

.poppins-extrabold-italic {
  font-family: "Poppins ExtraBold Italic", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black {
  font-family: "Poppins Black", sans-serif;
  font-weight: 900;
}

.poppins-black-italic {
  font-family: "Poppins Black Italic", sans-serif;
  font-weight: 900;
  font-style: italic;
}
/* #endregion */

/* #region [CSS Classes - Roboto] */
.roboto-thin {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.roboto-light {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.roboto-regular {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.roboto-medium {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.roboto-bold {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.roboto-black {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.roboto-thin-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.roboto-light-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.roboto-regular-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.roboto-medium-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.roboto-bold-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.roboto-black-italic {
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-style: italic;
}

/* #endregion */

/* #region [CSS Classes - clicker-script] */

@font-face {
  font-family: "Clicker Script";
  src: url("./assets/fonts/Clicker_Script/ClickerScript-Regular.ttf")
    format("truetype");
}

.clicker-script-regular {
  font-family: "Clicker Script", cursive;
  font-weight: 1000;
}

/* #endregion */

/* #region [CSS Classes - kaushan-script] */

@font-face {
  font-family: "Kaushan Script";
  src: url("./assets/fonts/Kaushan_Script/KaushanScript-Regular.ttf")
    format("truetype");
}

.kaushan-script-regular {
  font-family: "Kaushan Script", cursive;
  font-weight: 400;
  font-style: normal;
}

/* #endregion */

/* #region [CSS Classes - dancing-script] */

@font-face {
  font-family: "Dancing Script";
  src: url("./assets/fonts/Dancing_Script/DancingScript-VariableFont_wght.ttf")
    format("truetype");
}

.dancing-script-Bold {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

/* #endregion */

/*
html,
body {
  height: 100%;
  margin: 0;
} */

body {
  background-color: #f6f5fb;
  /* zoom: 0.9; */
}

* {
  font-family: "Poppins", sans-serif;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--button-color-1);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--button-color-1);
}

/* #region [PrNG_filterMenu_v1] */
.PrNG_filterMenu_v1
  ::ng-deep
  .ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu {
}

/* Filter Operator */
.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-operator {
  padding: 12px 16px;
}

.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-operator
  .p-inputwrapper
  .p-column-filter-operator-dropdown
  span {
  padding: 8px 12px;
  font-size: 14px;
}
.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-operator
  .p-inputwrapper
  .p-column-filter-operator-dropdown
  .p-dropdown-trigger
  .p-icon-wrapper
  svg {
  height: 14px;
}

/* Filter Constrains */
.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-constraints
  .p-column-filter-constraint {
  padding: 12px 16px;
}

/* DropDown */
.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-constraints
  .p-column-filter-constraint
  .p-inputwrapper
  .p-column-filter-matchmode-dropdown
  span {
  padding: 8px 12px;
  font-size: 14px;
}
.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-constraints
  .p-column-filter-constraint
  .p-inputwrapper
  .p-column-filter-matchmode-dropdown
  .p-dropdown-trigger
  .p-icon-wrapper
  svg {
  height: 14px;
}

/* Input */
.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-constraints
  .p-column-filter-constraint
  .p-element
  .p-inputtext {
  padding: 8px 12px;
  font-size: 14px;
}

/* Add Rule */
.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-add-rule {
  padding: 12px 16px;
}

/* Button Bar */
.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-buttonbar {
  padding: 12px 16px;
  justify-content: space-evenly;
}

/* Buttons */
.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-buttonbar
  button {
  padding: 8px 20px;
  border-radius: 25px;
}

/* Button - Clear */
.ng-trigger.p-column-filter-overlay.p-column-filter-overlay-menu
  .p-column-filter-buttonbar
  button.p-button-outlined {
  color: var(--PrNG-text-color-C);
  border: 2px solid var(--PrNG-border-color-C);
}

/* #endregion */
